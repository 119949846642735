export const locale = {
    mixed: {
      default: 'Campo inválido',
      required: '${label} es obligatorio',
      oneOf: '${label} debe ser uno de los siguientes valores: ${values}',
      notOneOf: '${label} no debe ser uno de los siguientes valores: ${values}',
      notType: '${label} tiene un tipo de dato erroneo',
    },
    string: {
      length: '${label} debe tener exactamente ${length} caracteres',
      min: '${label} debe tener al menos ${min} caracteres',
      max: '${label} debe tener como máximo ${max} caracteres',
      matches: '${label} no coincide con el formato requerido',
      email: '${label} tiene un formato de correo inválido',
      url: '${label} debe ser una URL válida',
      trim: '${label} no debe tener espacios al principio o al final',
      lowercase: '${label} debe estar en minúsculas',
      uppercase: '${label} debe estar en mayúsculas',
    },
    number: {
      min: '${label} debe ser mayor o igual que ${min}',
      max: '${label} debe ser menor o igual que ${max}',
      lessThan: '${label} debe ser menor que ${less}',
      moreThan: '${label} debe ser mayor que ${more}',
      notEqual: '${label} no debe ser igual a ${notEqual}',
      positive: '${label} debe ser un número positivo',
      negative: '${label} debe ser un número negativo',
      integer: '${label} debe ser un número entero',
    },
    date: {
      min: '${label} debe ser mayor o igual que ${min}',
      max: '${label} debe ser menor o igual que ${max}',
    },
    boolean: {
      isValue: '${label} debe ser ${value}',
    },
    object: {
      noUnknown: '${label} tiene claves no especificadas en la forma del objeto',
    },
    array: {
      min: '${label} debe tener al menos ${min} elementos',
      max: '${label} debe tener como máximo ${max} elementos',
    },
  };