import React,{useContext, createContext} from 'react';

export const SessionContext = createContext(null);

export function useSessionContext(){
  return useContext(SessionContext);
}

export function withSessionContext(Component){
  return (props) =>{
    return <SessionContext.Consumer>
        {(context) => {
            return <Component {...props} context={context}/>
        }}
        </SessionContext.Consumer>
  }
}
