
import { Storage } from 'aws-amplify';

const isProd = false;

export const AMPLIFY_CONFIG = {
  s3:{
    REGION: "us-east-1",
    BUCKET: process.env.REACT_APP_BUCKET_MANTENEDOR
    // BUCKET: isProd? "mantenedorprod" :"mantenedordev"
  },
  apiGateway:{
    REGION: "us-east-1",
    URL: process.env.REACT_APP_BACKEND_URL,
    // URL: isProd? 
    //   "https://i2g6u7yr62.execute-api.us-east-1.amazonaws.com/prod":
    //   "http://localhost:3000/dev"
  },
  cognito:{
    REGION: "us-east-1",
    USER_POOL_ID: process.env.REACT_APP_USER_POOL,
    APP_CLIENT_ID: process.env.REACT_APP_USER_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL
    // USER_POOL_ID: "us-east-1_DmxxPgRHn",
    // APP_CLIENT_ID: "4r6uca4viq3c2hq4r1shj3iss9",
    // IDENTITY_POOL_ID: "us-east-1:0eac38fb-ed63-45e2-9852-a4e839d35f65"
  }
}

export function setS3Config(bucket,level){
  
  Storage.configure({ 
    bucket: bucket,
    level: level,
    region: AMPLIFY_CONFIG.s3.REGION,
    identityPoolId: AMPLIFY_CONFIG.cognito.IDENTITY_POOL_ID
  });
}
