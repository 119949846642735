import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import { Amplify, Auth } from 'aws-amplify';
import { AMPLIFY_CONFIG } from './config';
import SessionHandler from 'src/containers/SessionHandler';
import "react-datepicker/dist/react-datepicker.css";
import * as yup from 'yup';
import { locale } from 'src/utils/locale';


yup.setLocale(locale);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));


Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: AMPLIFY_CONFIG.cognito.REGION,
    userPoolId: AMPLIFY_CONFIG.cognito.USER_POOL_ID,
    identityPoolId: AMPLIFY_CONFIG.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: AMPLIFY_CONFIG.cognito.APP_CLIENT_ID,
    storage: window.localStorage,
    cookieStorage: window.localStorage
  },
  Storage: {
    AWSS3: {
      bucket: AMPLIFY_CONFIG.s3.BUCKET, //REQUIRED -  Amazon S3 bucket
      region: AMPLIFY_CONFIG.s3.REGION, //OPTIONAL -  Amazon service region
      identityPoolId: AMPLIFY_CONFIG.cognito.IDENTITY_POOL_ID
    }
    //region: AMPLIFY_CONFIG.s3.REGION,
    //bucket: AMPLIFY_CONFIG.s3.BUCKET,
    //identityPoolId: AMPLIFY_CONFIG.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: "mantenedor-cdcc-api",
        endpoint: AMPLIFY_CONFIG.apiGateway.URL,
        region: AMPLIFY_CONFIG.apiGateway.REGION,
        custom_header: async () => {
          // return { Authorization: 'token' };
          // Alternatively, with Cognito User Pools use this:
          // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        }
      },
    ]
  }
});



class App extends Component {


  render() {


    return (
     <SessionHandler>
        <HashRouter>
            <React.Suspense fallback={loading}>
                <Switch>
                  <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                  {/* <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} /> */}
                  <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                  <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                  <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
                </Switch>
            </React.Suspense>
        </HashRouter>
      </SessionHandler>
    );
  }
}

export default App;
