import { createStore } from 'redux'

import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { persistStore, persistReducer } from 'redux-persist'

const initialState = {
  sidebarShow: 'responsive',
  session: {
    isAuthorized:false,
    data: null
  }
}

const rootReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    default:
      return state
  }
}

const persistConfig = {
  key:'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig,rootReducer)

export default ()=>{
  const store = createStore(persistedReducer);
  const persistor = persistStore(store);
  return {store, persistor};
}
