import React,{Component} from 'react';
import { Auth } from 'aws-amplify';
import { SessionContext } from 'src/context/sessionContext';
import { connect } from 'react-redux';

class SessionHandler extends Component{

  constructor(props){
    super(props);
    this.state = {
      session:{
        isAuthorized:false,
        data: null,
        isAuthenticating: true
      }
    };
    this.userHasAuthenticated.bind(this);
    this.setIsAuthenticating.bind(this);
  }

  componentDidMount(){
    this.setState({
      session:{
        isAuthenticating: false,
        ...this.props.session
      }
    });
    this.onLoad();
  }


  //Carga el estado de la sessión
  async onLoad(){

    this.setIsAuthenticating(true);
    try{
      const sessionData = await Auth.currentSession();
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentTimestamp = Math.round((new Date()).getTime() / 1000);
      const tokenExpiration = sessionData.getAccessToken().getExpiration();

      //console.log('Token',sessionData);
      //console.log('Sessión',cognitoUser);
      //console.log('timestamp', currentTimestamp ,tokenExpiration);

      if(currentTimestamp < tokenExpiration){

        //console.log('No renueva token');
        this.userHasAuthenticated(true,cognitoUser);
        this.setIsAuthenticating(false);
        return;
      }

      //Intenta renovar el token
      cognitoUser.refreshSession(sessionData.refreshToken,(err,session)=>{
        if(err){
          console.log('Error',err);
          this.userHasAuthenticated(false,cognitoUser);
        }else{
          this.userHasAuthenticated(true,cognitoUser);
        }

        this.setIsAuthenticating(false);
      });

    }catch(e){
      console.log('Session Error',e);
      this.userHasAuthenticated(false,null);
      this.setIsAuthenticating(false);
    }

  }

  userHasAuthenticated = (isAuthorized,data) => {
    this.setState((oldState) => {
      oldState.session.isAuthorized= isAuthorized;
      oldState.session.data = data;
      return oldState;
    })
  }

  setIsAuthenticating = (val) => {
    this.setState(oldState => {
      oldState.session.isAuthenticating = val;
      return oldState;
    });
  }

  render(){
    const { session } = this.state;
    const {userHasAuthenticated} = this;

    // console.log("Session render",session);

    const {children} = this.props;
    return <SessionContext.Provider value={{session, userHasAuthenticated}}>
        {!session.isAuthenticating ? children: ''}
      </SessionContext.Provider>;
  }
}

const mapStateToProps = state => {
  return {
    session: state.session
  };
}

export default connect(
  mapStateToProps
)(SessionHandler);
